import React from "react";
import PropTypes from "prop-types";
//components:
import Avatar from "../../components/avatar/Avatar";
//classes:
// import { lang } from "../../classes/lang";
import ImageCached from "../../classes/cache";
import { go } from "../../classes/routesLazy";
import IconCa from "../../classes/iconCa";
import { gameNameNormalized, showModal } from "../../classes/utills";
const WidgetSlots = (props) => {
	const { k_ey, el, un } = props;
	let _name = gameNameNormalized(el.other.name);
	let _provider = gameNameNormalized(el.other.provider);

	return (
		<div key={`${k_ey}_${el._id}`} className={`wSlots ${el.win ? "w" : "l"} animSlideDownFadeIn`}>
			<div className="shareBtn">
				<button
					disabled={false}
					onClick={(e) => [e.stopPropagation(), showModal("slottransaction", true, { _id: el._id, type: el.game })]}
					className={`diceBtn ${el.win ? "green" : "red"}`}
				>
					{/* <ImageCached width={24} height={24} storageName="ui" url={`/assets/button_logo.png`} params={{}} /> */}
					<IconCa name={`${el.game === "flappybet" ? "flappybet" : "seven"}`} />
				</button>
			</div>
			<Avatar userName={k_ey !== "my_bets" ? el.userName : un} uid={el.uid} img={el.img} size={24} />
			<div
				className="flex1 info"
				onClick={(e) => [
					e.stopPropagation(),
					el.game === "flappybet" ? go(`originals/${_name}/${el.other.gid}/real`) : go(`slot/${_name}/${_provider}`),
				]}
			>
				<div className="wrapper">
					<img src={el.other.image} alt="" title={el.other.name} />
					<div className="detail">
						<b className="name">{el.other.name}</b>
						<b className="provider">{el.other.provider}</b>
					</div>
				</div>
			</div>
			<div className={`profit ${el.win ? "w" : "l"}`}>
				{el.bet > 0 && <b>{el.bet.toFixed(12).substring(0, 10)}</b>}
				{el.profit > 0 && <b>{el.profit.toFixed(12).substring(0, 10)}</b>}
				{el.bet <= 0 && el.profit <= 0 && (0).toFixed(12).substring(0, 10)}
			</div>
			<ImageCached width={24} height={24} storageName="coin" url={`/assets/icons/${el.short}@2x.png`} alt={el.short} title={el.short} params={{}} />
		</div>
	);
};
WidgetSlots.propTypes = {
	k_ey: PropTypes.string,
	el: PropTypes.object,
	un: PropTypes.string,
};
export default WidgetSlots;
